import { LOGIN, STORE_USER_DETAILS, LOGOUT } from "../actions";
import { store } from "../configureStore";

export const login = (payload) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const storeUserDetails = (payload) => {
  return {
    type: STORE_USER_DETAILS,
    payload: {
      ...payload.Customer,
      email: payload.email,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("@dc-refresh-token");
  localStorage.removeItem("@dc-access-token");
  store.dispatch({ type: LOGOUT });
};
